import Raven from 'raven-js'
import { SENTRY_URL } from '../constants'
import { version } from '../../../package.json'
function configSentry() {
  if (!(process.env.REACT_APP_DEPLOYMENT_ENV === 'production')) {
    return null
  }
  return Raven.config(SENTRY_URL, {
    release: version
  }).install()
}
export default configSentry()
