import React from 'react'
import { Redirect } from 'react-router-dom'
import cacheToken from 'services/cache/token'
import * as AccountApi from 'services/api/account'

export default class Authorization extends React.Component {
  render() {
    const { match, location, children } = this.props

    if (match.query.auth_token) {
      cacheToken.write(match.query.auth_token)
      AccountApi.setAuthToken(match.query.auth_token)
      return <Redirect to={location.pathname} />
    }
    if (location.pathname === '/auth' && !!cacheToken.read()) {
      return <Redirect to={'/dapei/shops'} />
    }
    if (
      location.pathname.startsWith('/accounts') &&
      !AccountApi.hasAccountsPermission()
    ) {
      return <Redirect to={'/dapei/shops'} />
    }

    return children
  }
}
