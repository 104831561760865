import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import Loadable from 'react-loadable'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LocaleProvider } from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'

import {
  APP_NAME,
  APP_VERSION,
  APP_DESCRIPTION,
  APP_SHA
} from 'services/constants'
import store from 'services/store'
import history from 'services/history'
import AuthorizationComponent from './components/AuthorizationComponent'

const EditLook = Loadable({
  loading: () => null,
  loader: () => import('scenes/EditLook')
})

const Auth = Loadable({
  loading: () => null,
  loader: () => import('scenes/Auth')
})

const Nav = Loadable({
  loading: () => null,
  loader: () => import('scenes/Nav')
})

const ShopList = Loadable({
  loading: () => null,
  loader: () => import('scenes/ShopList')
})

const ShopItems = Loadable({
  loading: () => null,
  loader: () => import('scenes/ShopItems')
})

const Accounts = Loadable({
  loading: () => null,
  loader: () => import('scenes/Accounts')
})

const Account = Loadable({
  loading: () => null,
  loader: () => import('scenes/Account')
})

const StyleFeedback = Loadable({
  loading: () => null,
  loader: () => import('scenes/StyleFeedback')
})

const RouteNotFound = () => <Redirect to="/auth" />

function App() {
  return (
    <LocaleProvider locale={locale}>
      <Provider store={store}>
        <Router history={history}>
          <Fragment>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
              />
              <title>暖暖搭配师</title>
            </Helmet>
            <AuthorizationComponent>
              <Switch>
                <Route exact path="/auth" component={Auth} />
                <Fragment>
                  <Route path="/" component={Nav} />
                  <Switch>
                    <Route
                      exact
                      path="/dapei/edit_style/:sid/:num_iid"
                      component={EditLook}
                    />
                    <Route exact path="/dapei/shops" component={ShopList} />
                    <Route
                      exact
                      path="/dapei/shop_items/:sid"
                      component={ShopItems}
                    />
                    <Route exact path="/accounts" component={Accounts} />
                    <Route exact path="/accounts/:userId" component={Account} />
                    <Route
                      exact
                      path="/style_feedback/:sid"
                      component={StyleFeedback}
                    />
                    <Route component={RouteNotFound} />
                  </Switch>
                </Fragment>
              </Switch>
            </AuthorizationComponent>
          </Fragment>
        </Router>
      </Provider>
    </LocaleProvider>
  )
}

export default App
