export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const SENTRY_URL =
  'https://cdb7dfcc99c04c6cb659143c60f73ea4@sentry.prd.lain.leyanbot.com/46'

if (
  process.env.NODE_ENV === 'production' &&
  !process.env.REACT_APP_API_ENDPOINT
) {
  throw new Error(
    'environment variable REACT_APP_API_ENDPOINT should be provided for production build'
  )
}

let REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

if (!REACT_APP_API_ENDPOINT) {
  REACT_APP_API_ENDPOINT = '/'
} else {
  REACT_APP_API_ENDPOINT = REACT_APP_API_ENDPOINT + '/stylist/v0'
}

export const API_ENDPOINT = REACT_APP_API_ENDPOINT

let TOKEN_PREFIX = 'http://localhost:3000/#/?auth_token='
if (process.env.NODE_ENV === 'production') {
  TOKEN_PREFIX = process.env.REACT_APP_CLIENT_URL + '/#/?auth_token='
}

export { TOKEN_PREFIX }
